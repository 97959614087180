import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { setToken, setUser } from './Redux_Store/Slices/UserSlice';
import axios from 'axios';
import { Button, TextField, Checkbox, FormControlLabel, CircularProgress, IconButton, InputAdornment, Container, Paper, Typography, Box } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { StoreController } from './Controllers/store_controller.tsx';
import { ApiStatus } from '../api/api_url.ts';
import { GToaster } from '../helper/g_toaster.tsx';


const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const [searchParams] = useSearchParams();
  const storeController = new StoreController();
  const toaster = new GToaster();
  const storeId = searchParams.get('id');

  const handleLogin = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await storeController.login({ email, password, store: storeId });
      console.log(response);
      if (response?.status == ApiStatus.STATUS_200) {
        dispatch(setUser({ userData: response.data.data.user_data, token: response.data.data.access_token }));
        // dispatch(setToken(response.data.data.access_token));
        // if (rememberMe) {
        //   localStorage.setItem('user', JSON.stringify(response.data.userData));
        // }
        navigate(`/${search}`);
      } else {
        setError('Invalid credentials or server error');
        throw new Error('Invalid Response from server');
      }
    }
    catch (error) { toaster?.warning({ title: `${error}` }) }
    finally { setLoading(false) }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container maxWidth="sm" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
      <Paper elevation={3} sx={{ padding: 4, width: '100%' }}>
        <Box textAlign="center" mb={2}>
          <Typography variant="h4" component="h1" gutterBottom>
            Login
          </Typography>
        </Box>
        <TextField
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          fullWidth
          margin="normal"
          variant="outlined"
        />
        <TextField
          label="Password"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          fullWidth
          margin="normal"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleTogglePasswordVisibility}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {/* <FormControlLabel
          control={<Checkbox checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />}
          label="Remember Me"
          sx={{ marginTop: 2 }}
        /> */}
        {error && <Typography color="error" sx={{ marginTop: 2 }}>{error}</Typography>}
        <Button
          variant="contained"
          color="primary"
          onClick={handleLogin}
          disabled={loading}
          fullWidth
          sx={{ marginTop: 3, padding: 1.5 }}
        >
          {loading ? <CircularProgress size={24} /> : 'Login'}
        </Button>
        {/* <Box textAlign="center" mt={2}>
          <Typography variant="body2">
            <a href="/password-recovery" style={{ textDecoration: 'none', color: '#1976d2' }}>Forgot Password?</a>
          </Typography>
        </Box> */}
      </Paper>
    </Container>
  );
};

export default Login;

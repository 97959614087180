import { Checkbox, Chip, Divider, Grid, Typography } from "@mui/material";
import React from "react";

function NewTakeAwayDineIn({ tabBtnStatus, orders, element, foundStatus, mainIndex, title, handleCutItem }) {
    return (
        orders?.length > 0 && (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" className="orderType">
                        {title}
                    </Typography>
                </Grid>
                {orders?.map((order, orderIndex) => (
                    <React.Fragment key={orderIndex}>
                        <Grid item xs={12} md={1}>
                            <Typography className="typography-padding">
                                <b>{order?.order_id}</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={11} className="order-items-container">
                            {order?.product?.map((productItem, productIndex) => (
                                <React.Fragment key={productIndex}>
                                    <Grid container spacing={1} className="cardItems">
                                        <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center" pt="0px !important">
                                            <Typography
                                                paragraph
                                                className="typography-margin"
                                                sx={{
                                                    textDecoration: productItem?.include && tabBtnStatus === "newOrder" ? "line-through" : "none",
                                                    marginTop: tabBtnStatus === "completeOrder" || element?.is_new || foundStatus ? "8px" : "0px",
                                                    mb: 0,
                                                }}
                                            >
                                                {tabBtnStatus === "newOrder" && !element?.is_new && (
                                                    <Checkbox
                                                        checked={!!productItem?.include}
                                                        size="medium"
                                                        color="warning"
                                                        style={{ paddingLeft: "0px" }}
                                                        onChange={() => handleCutItem(productItem?.order_type, mainIndex, orderIndex, productIndex, !productItem?.include, element?._id)}
                                                    />)}
                                                <b>&nbsp;{`(${productItem?.quantity}x) ${productItem?.product?.name}`}</b>
                                            </Typography>
                                            {productItem?.include && tabBtnStatus === "newOrder" && (
                                                <Chip label="Done" color="success" variant="outlined" size="small" sx={{ float: "right" }} />
                                            )}
                                        </Grid>
                                        {!!productItem?.notes && <Grid item xs={12} className="product-notes" pt="0px !important">
                                            <Typography className="product-notes">{productItem?.notes}</Typography>
                                        </Grid>}
                                        <Grid item xs={12} className="product-notes-bottom">
                                            {productItem?.selected_pizza_variants?.size && (
                                                <Grid container spacing={0}>
                                                    {productItem?.selected_pizza_variants?.size?.size?.name && (
                                                        <Grid item xs={12}>
                                                            <span className="selected-pizza-variant">
                                                                <b>Size: </b>
                                                                {productItem?.selected_pizza_variants?.size?.size?.name}
                                                            </span>
                                                            <Divider sx={{ my: 1 }} />
                                                        </Grid>
                                                    )}
                                                    {productItem?.selected_pizza_variants?.size?.data?.sauces?.length > 0 && (
                                                        <Grid item xs={12}>
                                                            <span className="selected-pizza-variant">
                                                                {productItem?.selected_pizza_variants?.size?.data?.sauces?.map((sauceItem, sauceIndex) => (
                                                                    <React.Fragment key={sauceIndex}>
                                                                        <b>{`${sauceItem?.sauce_category?.category_name}: `}</b><br />
                                                                        {sauceItem?.data?.sauce_items?.map((sItem, index) => (
                                                                            <React.Fragment key={sItem?.sauce_item_data?.name}>
                                                                                {`${index + 1}. ${sItem?.sauce_item_data?.name} (${sItem?.left_data ? 'Left' : sItem?.right_data ? 'Right' : 'Whole'}), `}
                                                                                {`Type-${sItem?.sauce_type?.data?.name}`}
                                                                                <br />
                                                                            </React.Fragment>
                                                                        ))}
                                                                    </React.Fragment>
                                                                ))}
                                                            </span>
                                                            <Divider sx={{ my: 1 }} />
                                                        </Grid>
                                                    )}
                                                    {productItem?.selected_pizza_variants?.size?.data?.topping?.length > 0 && (
                                                        <Grid item xs={12}>
                                                            {productItem?.selected_pizza_variants?.size?.data?.topping?.map((toppingItem, toppingIndex) => (
                                                                <React.Fragment key={toppingIndex}>
                                                                    <span className="selected-pizza-variant">
                                                                        <b>{`${toppingItem?.topping_category?.category_name}: `}</b><br />
                                                                        {toppingItem?.data?.toppings_items?.map((toppItem, index) => (
                                                                            <React.Fragment key={toppItem?.topping_item_data?.name}>
                                                                                {`${index + 1}. ${toppItem?.topping_item_data?.name} (${toppItem?.left_data ? 'Left' : toppItem?.right_data ? 'Right' : 'Whole'}), `}
                                                                                {`Type-${toppItem?.toppings_types?.data?.name}`}
                                                                                <br />
                                                                            </React.Fragment>
                                                                        ))}
                                                                    </span>
                                                                </React.Fragment>
                                                            ))}
                                                            <Divider sx={{ my: 1 }} />
                                                        </Grid>
                                                    )}
                                                    {productItem?.selected_pizza_variants?.size?.data?.crust?.crust_data?.name && (
                                                        <Grid item xs={12}>
                                                            <span className="selected-pizza-variant">
                                                                <b>{productItem?.selected_pizza_variants?.size?.data?.crust?.crust_data?.name}: </b>
                                                            </span>
                                                            <span>{productItem?.selected_pizza_variants?.size?.data?.crust?.data?.crust_name}</span>
                                                            <Divider sx={{ my: 1 }} />
                                                        </Grid>
                                                    )}
                                                    {productItem?.selected_pizza_variants?.size?.data?.cheese && (
                                                        <Grid item xs={12}>
                                                            {productItem?.selected_pizza_variants?.size?.data?.cheese?.map((cheeseItem, cheeseIndex) => (
                                                                <React.Fragment key={cheeseIndex}>
                                                                    <span className="selected-pizza-variant">
                                                                        <b>{`${cheeseItem?.cheese_category?.category_name}: `}</b><br />
                                                                        {cheeseItem?.data?.cheese_items?.map((cheeItem, index) => (
                                                                            <React.Fragment key={cheeItem?.cheese_item_data?.name}>
                                                                                {`${index + 1}. ${cheeItem?.cheese_item_data?.name} (${cheeItem?.left_data ? 'Left' : cheeItem?.right_data ? 'Right' : 'Whole'}), `}
                                                                                {`Type-${cheeItem?.cheese_types?.cheese_type_data?.name}`}
                                                                                <br />
                                                                            </React.Fragment>
                                                                        ))}
                                                                    </span>
                                                                </React.Fragment>
                                                            ))}
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            )}
                                            {productItem?.selected_variants?.map((selectedVar, indexSelectedVar) => (
                                                <React.Fragment key={indexSelectedVar}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography className="typography-margin">
                                                                <b>{selectedVar?.variant_category?.name}</b>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} pt='0px !important'>
                                                            <Typography className="typography-margin-right" paragraph>
                                                                {selectedVar?.data?.items?.map((varItem, varItemIndex) => (
                                                                    <React.Fragment key={varItemIndex}>{varItem?.item_data?.variant_name}{selectedVar?.variant_category?.include_quantity ? <b>(x{varItem.quantity}),&nbsp;</b> : ', '}</React.Fragment>
                                                                ))}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </React.Fragment>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            ))}
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>
        )
    );
}

export default NewTakeAwayDineIn;

import { Checkbox, Chip, Divider, Grid, Typography } from "@mui/material";
import React from "react";

function TakeAwayDineIn({ TabBtnStatus, Orders, Element, FoundStatus, MainIndex, Title, handleCutItem }) {
  console.log(Orders);
  console.log(Element);
  console.log(!Element.is_new);
  console.log(!FoundStatus)
  return (
    <>
      <Grid container spacing={2}>
        {Orders?.length > 0 && (
          <Grid item xs={12}>
            <Typography variant={"h6"} className="orderType">
              {Title}
            </Typography>
          </Grid>
        )}
        {Orders?.map((order: Record<string, any>, orderIndex: number) => {
          return (
            <React.Fragment key={orderIndex}>
              <Grid item xs={12} md={1}>
                <Typography sx={{ paddingBottom: "0px", mb: "0px", pt: "8px" }}>
                  <b>{`${order?.order_id}`}</b>
                </Typography>
              </Grid>
              <Grid item xs={12} md={11}>
                {order?.product.map((productItem: Record<string, any>, productIndex: number) => (
                  <React.Fragment key={productIndex}>
                    <Grid container spacing={1} className="cardItems">
                      <Grid item xs={12} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography
                          paragraph={true}
                          sx={{
                            marginBottom: "0px",
                            textDecoration: productItem.include && TabBtnStatus == "newOrder" ? "line-through" : "none",
                            marginTop: TabBtnStatus == "completeOrder" || Element.is_new || FoundStatus ? "8px" : "0px",
                          }}
                        >
                          {TabBtnStatus == "newOrder" && !Element.is_new && !FoundStatus && (
                            <Checkbox checked={productItem?.include ? true : false} size="medium" color="warning" onChange={() => handleCutItem(productItem?.order_type, MainIndex, orderIndex, productIndex, !productItem?.include, Element._id)} />
                          )}
                          <b>
                            &nbsp;
                            {`(${productItem?.quantity}x) ${productItem?.product?.name}`}
                          </b>
                        </Typography>
                        {productItem?.include && TabBtnStatus == "newOrder" && <Chip label="Done" color="success" variant="outlined" size="small" sx={{ float: "right" }} />}
                      </Grid>
                      <Grid item xs={12} style={{ fontSize: "14px", color: "#595454", paddingLeft: "20px", paddingTop: "0px" }}>
                        <Typography className="product-notes">{productItem.notes}</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          fontSize: "14px",
                          color: "#595454",
                          paddingLeft: "20px",
                        }}
                      >
                        {productItem?.selected_pizza_variants != null && (
                          <Grid container spacing={0}>
                            {/* SHOW IS SIZE TRUE */}
                            {productItem?.selected_pizza_variants.size?.size?.name && (
                              <Grid item xs={12}>
                                <span className="selectedPizzaVariants">
                                  <b>Size : </b>
                                  {`${productItem?.selected_pizza_variants.size?.size?.name}`}
                                </span>
                                <Divider sx={{ my: 1 }} />
                              </Grid>
                            )}
                            {/* {SELECTED VARIANT PIZZA} show if sauces length > 0 */}
                            {productItem?.selected_pizza_variants.size?.data?.sauces?.length > 0 && (
                              <Grid item xs={12}>
                                <span className="selectedPizzaVariants">
                                  {productItem?.selected_pizza_variants.size?.data?.sauces?.map((sauceItem: Record<string, any>, sauceIndex: number) => (
                                    <React.Fragment key={sauceIndex}>
                                      <b>{`${sauceItem.sauce_category.category_name} : `}</b>
                                      {sauceItem.data?.sauce_items?.map((sItem: Record<string, any>) => sItem?.sauce_item_data?.name)}
                                    </React.Fragment>
                                  ))}
                                </span>
                                <Divider sx={{ my: 1 }} />
                              </Grid>
                            )}
                            {/* SHOW TOPPING IS LENGTH > 0 */}
                            {productItem?.selected_pizza_variants.size?.data?.topping?.length > 0 && (
                              <Grid item xs={12}>
                                {productItem?.selected_pizza_variants.size?.data?.topping?.map((toppingItem: Record<string, any>, toppingIndex: number) => (
                                  <React.Fragment key={toppingIndex}>
                                    <span
                                      style={{
                                        width: "100%",
                                      }}
                                    >
                                      <b>{`${toppingItem.topping_category.category_name} : `}</b>
                                      {toppingItem.data?.toppings_items?.map((toppItem: Record<string, any>) => " " + toppItem?.topping_item_data?.name + ",")}
                                    </span>
                                    <br />
                                  </React.Fragment>
                                ))}

                                <Divider sx={{ my: 1 }} />
                              </Grid>
                            )}
                            {productItem?.selected_pizza_variants.size?.data?.crust?.crust_data.name && (
                              <Grid item xs={12}>
                                <span className="selectedPizzaVariants">
                                  <b>{`
                      ${productItem?.selected_pizza_variants.size?.data?.crust?.crust_data.name} : `}</b>
                                </span>
                                <span>{productItem?.selected_pizza_variants.size?.data?.crust?.data.crust_name}</span>
                                <Divider sx={{ my: 1 }} />
                              </Grid>
                            )}
                            {productItem?.selected_pizza_variants.size?.data?.cheese && (
                              <Grid item xs={12}>
                                {productItem?.selected_pizza_variants.size?.data?.cheese?.map((cheeseItem: Record<string, any>, cheeseIndex: number) => (
                                  <React.Fragment key={cheeseIndex}>
                                    <span
                                      style={{
                                        width: "100%",
                                      }}
                                    >
                                      <b>{`${cheeseItem.cheese_category.category_name} : `}</b>
                                      {cheeseItem.data?.cheese_items?.map((cheeItem: Record<string, any>) => " " + cheeItem?.cheese_item_data?.name + ",")}
                                    </span>
                                    <br />
                                  </React.Fragment>
                                ))}
                              </Grid>
                            )}
                          </Grid>
                        )}
                        {/* //SELECTED VARIANT */}
                        {productItem?.selected_variants?.map((selectedVar: Record<string, any>, indexSelectedVar: number) => (
                          <React.Fragment key={indexSelectedVar}>
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <Typography marginBottom={0}>
                                  <b>{selectedVar?.variant_category?.name}</b>
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography marginRight={5} mt={"-5px"} paragraph={true}>
                                  {selectedVar?.data?.items.map((varItem, varItemIndex) => (
                                    <React.Fragment key={varItemIndex}>{varItem?.item_data?.variant_name},</React.Fragment>
                                  ))}
                                </Typography>
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        ))}
                      </Grid>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    </>
  );
}

export default TakeAwayDineIn;
